import { Header } from "./components/Header";
import { SecurityPolicy } from "./components/SecurityPolicy";
import { TermsOfService } from "./components/TermsOfService";
import { Hero } from "./components/Hero";
import { PrimaryFeatures } from "./components/PrimaryFeatures";
import { UseCases } from "./components/UseCases";
// import { SecondaryFeatures } from './components/SecondaryFeatures'; Not required for now
import { CallToAction } from "./components/CallToAction";
import { Testimonials } from "./components/Testimonials";
import { CaseStudy } from "./components/CaseStudy";
// import { Pricing } from './components/Pricing'; Not required for now
import { PricingPlans } from "./components/PricingPlans";
import { Faqs } from "./components/Faqs";
import { Footer } from "./components/Footer";
import { Route, Routes, Navigate } from "react-router-dom";
import { MergedTuskIssues } from "./components/MergedTuskIssues";
import { HitpayCaseStudyArticle } from "./components/HitpayCaseStudyArticle";
import { HitpayWhiteSvg } from "./components/CompanyLogos";
import { PrefixCaseStudyArticle } from "./components/PrefixCaseStudyArticle";
import { PrefixLogoWhiteSvg } from "./components/CompanyLogos";

export const MarketingPage = () => {
  // all the stuff from index.jsx
  // Footer
  return (
    <>
      <Header />
      <Routes>
        <Route path="/security" element={<Navigate replace to="/privacy" />} />
        <Route path="/privacy" element={<SecurityPolicy />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/hitpay-case" element={<HitpayCaseStudyArticle />} />
        <Route path="/prefix-case" element={<PrefixCaseStudyArticle />} />
        <Route
          path="/"
          element={
            <>
              <Hero />
              <PrimaryFeatures />
              <UseCases />
              <div className="flex flex-col md:flex-row pb-16 sm:pb-24">
                <CaseStudy
                  className="hover:scale-105 transition-all duration-300 mb-12 sm:mb-0 sm:ml-12 sm:mr-6 lg:mr-8"
                  backgroundImageUrl="https://storage.googleapis.com/use-tusk-dev-static/hitpay_tusk_example_case_edited.png"
                  logoComponent={<HitpayWhiteSvg className="h-8 w-auto" />}
                  quote="Tusk takes weight away from our developers. It's truly been a copilot for our developers as well as product managers."
                  authorName="Nitin Muthyala"
                  authorTitle="Co-founder & CTO of HitPay (YC S21)"
                  caseStudyLink="/hitpay-case"
                />
                <CaseStudy
                  className="hover:scale-105 transition-all duration-300 sm:mr-12 sm:ml-6 lg:ml-8"
                  backgroundImageUrl="https://storage.googleapis.com/use-tusk-dev-static/Prefix%20Case%20Study%20-%20Workflow%20Graphic.png"
                  logoComponent={<PrefixLogoWhiteSvg className="h-8 w-auto" />}
                  quote="I write less code but I ship more. This is my new flow and I don't want to go back to how I was working before."
                  authorName="Dan García"
                  authorTitle="Founding Product Engineer at Prefix"
                  caseStudyLink="/prefix-case"
                />
              </div>
              <MergedTuskIssues />
              {/* <SecondaryFeatures /> */}
              <Testimonials />
              {/* <Pricing /> */}
              <PricingPlans />
              <Faqs />
              <CallToAction />
            </>
          }
        />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      <Footer />
    </>
  );
};
